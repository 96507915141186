<template>
    <modal class="selectMouldModel" :isShow="isShow">
        <div class="header">按模板设置限量菜品份数</div>
        <div class="modal-body">
            <div class="tr">
                <span class="lable-txt">营业日期：</span>
                <el-date-picker class="from-date"
                v-model="businessHours"
                type="date"
                placeholder=""
                :default-value="new Date()">
                </el-date-picker>
                <div class="inlineBox">
                    <span class="text-label">限售时段：</span>
                    <el-select v-model="salesValue" placeholder="全天">
                        <el-option value="" label="全天"></el-option>
                        <el-option
                        v-for="item in eatPhases"
                        :key="item"
                        :label="item.EAT_PHASENAME"
                        :value="item.EAT_PHASEID">
                        </el-option>
                    </el-select>
                </div>
                <div class="inlineBox">
                    <span class="text-label">模板：</span>
                    <el-select v-model="tempValue" placeholder="请选择" value-key="Tmplate_ID">
                        <el-option
                            v-for="item in limitTempList"
                            :key="item"
                            :label="item.Tmplate_Name"
                            :value="item">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="table-box grey-table" v-table-el-height="'tableEl'">
                <el-table ref="tableEl" border :data="limitFoods"  style="width: 100%;"  
                :row-class-name="({row})=>row.Food_ID==currentFoodID?'current-row':''"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column prop="SubCategory_Name" label="菜品类别" min-width="100" align="left"></el-table-column> 
                    <el-table-column prop="Food_Code" label="菜品编号" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Food_Name" label="菜品名称" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Food_Size" label="菜品单位" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Limited_Num" label="剩余库存数量" width="120"> 
                        <template #default="scope">
                            <el-button type="text" size="small" v-if="sellOffKey[scope.row.Food_ID]" @click="cancelDoSoldOut(sellOffKey[scope.row.Food_ID])">取消沽清</el-button>
                            <div class="num" v-else>
                                <input-pattern class="limitedNum" :data-id="scope.row.Food_ID" 
                                    v-model="scope.row.Limited_Num" pattern="number" :min="0" :dplaces="2" @focus="currentFoodID=scope.row.Food_ID" @blur="currentFoodID=null"
                                    @keydown="inputDown"
                                ></input-pattern>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Sold_Num" label="已售数量" width="70" align="right">
                        <template #default="scope">
                            <template v-if="!sellOffKey[scope.row.Food_ID]">
                                {{scope.row.Sold_Num}}
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EAT_PHASENAME" label="限售时段" width="70">
                        <template #default="scope">
                            <template v-if="!sellOffKey[scope.row.Food_ID]">
                                {{eatPhasesKey[salesValue]||"全天"}}
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="60">
                        <template #default="scope">
                            <i class="iconfont icon-shanchu" @click="delTempFood(scope.row,scope.$index)"></i>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm(true)">保存并退出</button>
            <button class="btn btn-confirm" @click="confirm()">保存并继续设置</button>
        </div>
    </modal>
</template>

<script>
// 按模板设置限量菜品份数
export default {
    name:'selectMouldModel',
    emits:["removeSoldOut","confirm","closeModel"],
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            /**数据 记载中 */
            tableLoading:true,
            /**营业日期 */
            businessHours:new Date(),
            /**沽清数据 */
            tableData:[],
            //时段
            eatPhases:[],
            //选择时段
            salesValue:'',
            //模板数据
            limitTempList:[],
            //选择模板
            tempValue:null,
            //要沽清的数据
            limitFoods:null,
            //高亮的行的菜品
            currentFoodID:null
        }
    },
    computed:{
        eatPhasesKey(){
            let key={};
            this.eatPhases.forEach(it=>{
                key[it.EAT_PHASEID]=it.EAT_PHASENAME;
            })
            return key;
        },
        /**沽清数据 */
        sellOffKey(){
            let key={};
            this.tableData?.forEach(it=>{
                if(it.SoldOut_Type==0){//沽清的数据
                    key[it.Eat_XFBMID]=it;
                }
            })
            return key;
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        businessHours(){
            this.loadData();
        },
        tempValue(){
            this.limitFoods= this.tempValue?.TemplateLimitFoods?.map(it=>{
                return Object.assign({},it);
            })
            this.isZero=false;
            //更新已售数量
            this.upSoldNum();
        },
        tableData(){
            this.isZero=false;
            this.upSoldNum();
        },
        salesValue(){
            this.isZero=false;
            this.upSoldNum();
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            this.tempValue=null;
            this.limitFoods=null;
            this.salesValue='';
            this.isZero=false;

            let userInfo= this.$auth.getUserInfo();
            if(userInfo){//营业日期
                this.businessHours=new Date(userInfo.Rpt_Date);
            }
            this.$cacheData.EatPhase().then((d)=>{
                if(d){
                    this.eatPhases=d;
                }
            }).catch((e)=>{
                this.$message.error('加载时段数据失败：'+e,);
                console.log('加载时段数据失败：'+e);
            })
            this.loadData();
            this.loadTempData();
        },
        /**加载沽清数据 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetSoldOutInfo",{
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                Operator_Name:userInfo?.Login_Name,
                User_ID:userInfo?.User_ID
            }).then((d)=>{
                loading.close();
                console.log(d)
                if(d.ResponseHeader.ResultCode==0){
                    this.tableData=d.ResponseBody.doSoldOutList;
                }else{
                    this.tableData=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableData=[];
                loading.close();
                console.log('加载沽清数据失败：'+e);
            })
        },
        //加载模板数据
        loadTempData(){
            const loading = this.$loading({
                text: "获取限量菜品设置模板数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetLimitTemplate", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.limitTempList=d.ResponseBody;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("限量菜品设置模板数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        //更新已售数量
        upSoldNum(){
            //更新已售数量
            this.limitFoods?.forEach(it=>{
                let f=this.tableData?.find(d=>d.Eat_XFBMID==it.Food_ID && d.EAT_PHASEID==this.salesValue && d.SoldOut_Type==2);
                if(f){
                    it.Sold_Num=f.Sold_Num;//已售数量
                    it.SoldOut_ID=f.SoldOut_ID;
                    it.Limited_Num=f.Limited_Num-f.Sold_Num;//剩余数量
                }else{
                    it.Sold_Num=0;
                    it.SoldOut_ID=null;
                    it.Limited_Num="";
                }
            })
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(isClose){
            let selectSoldOutData=[];
            let userInfo= this.$auth.getUserInfo();
            let date=new Date(userInfo.Rpt_Date).Format('yyyy-MM-dd');//当前营业日期
            let rptDate=(new Date(this.businessHours)).Format('yyyy-MM-dd')
            for(let i=0;i<this.limitFoods?.length;i++){
                let food=this.limitFoods[i];
                if(this.sellOffKey[food.Food_ID]){
                    continue;
                }
                if(food.Limited_Num==""){
                    this.$el.querySelector(".limitedNum[data-id='"+food.Food_ID+"']")?.focus();
                    this.$message.warning('请修改【剩余库存数量】后再保存');
                    return;
                }
                let Limited_Num=Number(food.Limited_Num);
                if(Limited_Num>0){
                    Limited_Num=Limited_Num+ Number(food.Sold_Num);
                }else if(rptDate!=date && Limited_Num==0 && !food.SoldOut_ID){//非营业日期时 剩余库存0 并且不是修改限量
                    this.$el.querySelector(".limitedNum[data-id='"+food.Food_ID+"']")?.focus();
                    this.$message.warning('请修改【剩余库存数量】后再保存');
                    return;
                }
                
                selectSoldOutData.push({
                    SoldOut_ID:food.SoldOut_ID,
                    Eat_XFBMID:food.Food_ID,
                    Eat_XFNAME:food.Food_Name,
                    Eat_XFKINDNAME:food.SubCategory_Name,
                    Limited_Num:Limited_Num,//限售数量
                    Sold_Num:food.Sold_Num,//已售数量
                    EAT_PHASEID:this.salesValue,//时段
                    EAT_PHASENAME:this.eatPhasesKey[this.salesValue]||"全天"
                })
            }

            if(selectSoldOutData.length==0){
                this.$message.warning('请修改【剩余库存数量】后再保存');
                return;
            }
            
            this.$emit("confirm",selectSoldOutData,rptDate,isClose);
        },
        inputDown(e){
            if(e.keyCode=='38' || e.keyCode=='40' || e.keyCode=='13'){
                let list=this.$el.querySelectorAll(".limitedNum[data-id]");
                let index=0;
                for(let i=0;i<list.length;i++){
                    if(list[i]==e.target){
                        index=i;
                    }
                }
                if(e.keyCode=='38'){//上
                    index=Math.max(index-1,0);
                    list[index]?.focus()
                    list[index]?.select();
                }else if(e.keyCode=='40'){//下
                    index=Math.min(index+1,list.length-1);
                    list[index]?.focus();
                    list[index]?.select();
                }else if(e.keyCode=='13'){//回车
                    if(list.length>index+1){
                        list[index+1]?.focus();
                    }else{
                        list[0].focus();
                    }
                }
                e.preventDefault();
            }
            
        },
        //移除
        delTempFood(data,index){
            this.limitFoods.splice(index,1);
        },
        /**取消沽清 */
        cancelDoSoldOut(row){
            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let doSoldOutList=[{
                SoldOut_ID:row.SoldOut_ID,//沽清id
                Eat_XFBMID:row.Eat_XFBMID,//菜品id
                Eat_XFNAME:row.Eat_XFNAME,//菜品名称
                SoldOut_Type:1,// 1 取消沽清  3取消限量销售
            }];
            console.log("取消沽清数据",{
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                doSoldOutList:doSoldOutList
            })
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.DoSoldOut",{
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                doSoldOutList:doSoldOutList
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("取消沽清成功");
                    doSoldOutList.forEach((row)=>{
                        this.removeData(row.SoldOut_ID);
                    })
                    
                }else{
                    this.$message.error("取消沽清失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('取消沽清失败：'+e.message);
                console.log('取消沽清失败：',e);
            }) 
        },
        /**移除指定沽清数据 */
        removeData(SoldOut_ID){
            let index=this.tableData?.findIndex(t=>t.SoldOut_ID==SoldOut_ID);
            if(index>=0){
                this.tableData.splice(index,1);
            }
            this.$emit("removeSoldOut",SoldOut_ID);//移除父级 沽清数据
        },
    }
}
</script>

<style lang="scss">
@import './selectMouldModel.scss'
</style>